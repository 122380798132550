import type { OmiseCreditCardConfig } from "@warrenio/api-spec/spec.oats.gen";
import { useImperativeHandle } from "react";
import invariant from "tiny-invariant";
import { useUnmountSignal } from "../../../utils/react/useUnmountSignal.tsx";
import { useApiClient } from "../../api/apiClient.store.ts";
import { TopUpResult, type TopUpProps } from "../topup/TopUpParams.ts";
import { creditBuyRequest, persistentDataRequest } from "../topup/topUpUtils.ts";
import { pollForOmiseSuccess } from "./parseOmisePersistentData.ts";

export function OmiseCardTopUpForm({ actionsRef }: { config: OmiseCreditCardConfig } & TopUpProps) {
    const apiClient = useApiClient();
    const signal = useUnmountSignal();

    useImperativeHandle(actionsRef, () => ({
        needsPopUp(_params) {
            return true;
        },
        async topUp({ amount, getCard, progress, account, popup, returnUrl }) {
            progress("Sending payment request");
            const buyResult = await creditBuyRequest(apiClient, {
                billing_account_id: account.id,
                payment_object_id: getCard().id,
                amount,
                on_session: true,
                // XXX: The server might add a suffix to this URL, but we don't care about it
                front_base_url: returnUrl,
                force_3ds: true,
            });

            const { authorize_uri, data_ref } = buyResult.relay_props;
            if (authorize_uri) {
                progress("Redirecting to Omise");

                invariant(popup, "Popup must be provided");
                popup.handleRedirect(authorize_uri);

                return await pollForOmiseSuccess(
                    { signal, popup },
                    async () => await persistentDataRequest(apiClient, account.id, data_ref!, signal),
                );
            } else {
                // TODO: Do we still need to check for success via persistent data here?
                return TopUpResult.SUCCESS;
            }
        },
    }));

    return null;
}

export default OmiseCardTopUpForm;
