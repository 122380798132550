import type { Ref } from "react";
import type { BillingAccountWithType } from "../billingAccountQuery.ts";
import type { PaymentPopup } from "../topup/popup.ts";

export const AddMethodResult = {
    /** Validation of form fields failed */
    VALIDATION_FAILED: "VALIDATION_FAILED",
    /** eg. modal was closed */
    CANCELLED: "CANCELLED",
    SUCCESS: "SUCCESS",
} as const;

export type AddMethodResult = (typeof AddMethodResult)[keyof typeof AddMethodResult];

export interface AddMethodParams {
    account: BillingAccountWithType;
    returnUrl: string;
    popup: PaymentPopup | undefined;

    /** Update progress information */
    progress: (status: string) => void;
}

export interface AddPaymentMethodParams extends Omit<AddMethodParams, "returnUrl"> {}

/** Actions exposed by an "Add method" form */
export interface AddMethodActions {
    validate(): Promise<boolean>;
    /** @returns should a pop-up window be opened to display the progress and handle any redirects? */
    needsPopUp?: (params: AddPaymentMethodParams) => boolean;
    // biome-ignore lint/suspicious/noConfusingVoidType: Backward compatibility
    addPaymentMethod(params: AddMethodParams): Promise<AddMethodResult | void>;
}

/** Properties for an "Add method" form */
export interface AddMethodProps {
    actionsRef: Ref<AddMethodActions>;
}
