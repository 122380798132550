import { notNull } from "@warrenio/utils/notNull";
import { ModalResult, WModalContent } from "../../../components/modal/WModal.tsx";
import { getResourceIconClass } from "../../api/resourceTypes.tsx";
import { raiseBackgroundErrorToast, raiseToast } from "../../notifications/toast.tsx";
import type { EBillingAccount } from "../billingLogic.tsx";
import { PaymentPopup } from "../topup/popup.ts";
import type { AddPaymentMethodParams } from "./AddMethodParams.ts";
import { ChooseMethod, type ChooseMethodProps } from "./ChooseMethod.tsx";
import { useAddMethod, type UseAddMethodProps } from "./useAddMethod.tsx";

export interface AddPaymentMethodModalProps extends UseAddMethodProps {
    billingAccount: EBillingAccount;
    onlyAutomated?: ChooseMethodProps["defaultOnlyAutomated"];
}

export function AddPaymentMethodModal({
    billingAccount: { account },
    onlyAutomated,
    ...useProps
}: AddPaymentMethodModalProps) {
    const adder = useAddMethod(useProps);

    function showError(message: string) {
        raiseToast({
            type: "error",
            icon: getResourceIconClass("billing_account"),
            message,
        });
    }

    return (
        <WModalContent
            title="Add Payment Method"
            label="Add"
            footerNotice={!adder.hasSelectedMethod ? "Please select an item in the list" : undefined}
            isActionDisabled={!adder.hasSelectedMethod}
            modalAction={async (ev) => {
                if (!(await adder.validate())) {
                    return ModalResult.KEEP_OPEN;
                }

                let popup: AddPaymentMethodParams["popup"];

                const addPaymentMethodParams: AddPaymentMethodParams = {
                    account,
                    progress(status) {
                        status += "…";
                        console.debug("Add payment method progress: %s", status);
                        if (popup) {
                            // TODO: Check if window closed
                            popup.setText(status);
                        }
                        // TODO: Also display inside modal
                    },
                    popup: undefined,
                };

                // NB: The popup must be opened synchronously (before anything `async`), otherwise the browser will block it
                const addActions = notNull(adder.props.formProps.actionsRef.current, "addMethodRef");
                if (addActions.needsPopUp?.(addPaymentMethodParams)) {
                    popup = PaymentPopup.open(ev.pointerType !== "virtual");
                    if (!popup) {
                        showError("Please allow popup windows to add this payment method");
                        return ModalResult.KEEP_OPEN;
                    }

                    addPaymentMethodParams.popup = popup;
                    addPaymentMethodParams.progress("Initiating adding payment method");
                }

                try {
                    await adder.addPaymentMethod(addPaymentMethodParams);
                } catch (e) {
                    raiseBackgroundErrorToast(e, "Error adding payment method");
                    return ModalResult.KEEP_OPEN;
                } finally {
                    // Automatically close popup on any case
                    popup?.close();
                }
                return ModalResult.CLOSE;
            }}
        >
            <ChooseMethod hasAutomatedSwitch defaultOnlyAutomated={onlyAutomated} scrollToSelected {...adder.props} />
        </WModalContent>
    );
}

export default AddPaymentMethodModal;
