import { notifyError } from "../../dev/errorReporting.tsx";

export function logPromiseErrors(message: string, results: PromiseSettledResult<unknown>[]) {
    for (const result of results) {
        if (result.status === "rejected") {
            notifyError(result.reason as unknown, { context: { message } });
            console.error(message, result.reason);
        }
    }
}
