import type { PersistentDataResponse } from "@warrenio/api-spec/spec.oats.gen";
import type { EmptyObject, LiteralUnion } from "type-fest";
import { failureToMessage } from "../topup/topUpUtils.ts";

export interface SslcommerzChargeData {
    status?: "VALID" | "HIGH_RISK" | "CANCELLED";
    amount_without_vat: number;
    amount_with_vat: number;
    currency: string;

    failure_code?: LiteralUnion<"payment_cancelled" | "high_risk", string>;
    failure_message?: string;
}

export class SslcommerzError extends Error {
    name = "SslcommerzError";

    constructor(
        public readonly code: "payment_cancelled" | "high_risk" | "other",
        message: string,
    ) {
        super(message);
    }
}

export function parseSslcommerzPersistentData(response: PersistentDataResponse) {
    const data = JSON.parse(response.data) as SslcommerzChargeData | EmptyObject;
    if (!("status" in data)) {
        return null;
    }

    switch (data.status) {
        case "VALID":
            return data;

        case "HIGH_RISK":
            throw new SslcommerzError("high_risk", "Payment cancelled due to high risk");
        case "CANCELLED":
            throw new SslcommerzError("payment_cancelled", "Payment cancelled");
        default:
            throw new SslcommerzError("other", failureToMessage(data));
    }
}
