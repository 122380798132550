import type { PaymentIntent, PaymentIntentResult, Stripe } from "@stripe/stripe-js";
import type {
    LinkParamsGeneric,
    MethodConfig,
    StripeWalletConfig,
    StripeWalletRedirectSendDataResponse,
} from "@warrenio/api-spec/spec.oats.gen";
import { useImperativeHandle, useState } from "react";
import invariant from "tiny-invariant";
import { useUnmountSignal } from "../../../utils/react/useUnmountSignal.tsx";
import { useApiClient } from "../../api/apiClient.store.ts";
import type { TopUpParams, TopUpProps } from "../topup/TopUpParams.ts";
import { creditListRequest, linkSendData, pollForSuccess } from "../topup/topUpUtils.ts";

interface AlipayHandleRedirect {
    type: "alipay_handle_redirect";
    alipay_handle_redirect: {
        url: string;
    };
}

export function StripeLinkTopUpForm({
    actionsRef,
    method: { key },
    stripe,
}: { config: StripeWalletConfig; method: MethodConfig } & TopUpProps & { stripe: Promise<Stripe> }) {
    //#region Hooks
    const [qrCode, setQrCode] = useState<{ qrcode_url: string } | null>(null);

    const apiClient = useApiClient();
    const signal = useUnmountSignal();

    useImperativeHandle(actionsRef, () => ({
        needsPopUp(_params) {
            return key !== "wechat_pay";
        },

        async topUp(params: TopUpParams) {
            const { progress, popup, account } = params;

            progress("Starting payment");
            const sendResult = await linkSendData<LinkParamsGeneric>(apiClient, params, "stripe_wallet", {
                paymentMethod: key,
            });

            const data = sendResult.data as StripeWalletRedirectSendDataResponse;
            const { front_redirect_method, client_secret, return_url } = data;

            invariant(front_redirect_method, "Expected front_redirect_method in response");
            invariant(client_secret, "Expected client_secret in response");
            invariant(return_url, "Expected return_url in response");

            const stripeApi = await stripe;
            let intentConfirmResult: PaymentIntentResult;
            let nextAction: PaymentIntent.NextAction | null = null;

            switch (key) {
                case "grabpay":
                    intentConfirmResult = await stripeApi.confirmGrabPayPayment(
                        client_secret,
                        {
                            return_url,
                        },
                        { handleActions: false },
                    );
                    break;
                case "alipay":
                    intentConfirmResult = await stripeApi.confirmAlipayPayment(
                        client_secret,
                        { return_url },
                        { handleActions: false },
                    );
                    break;
                case "wechat_pay":
                    intentConfirmResult = await stripeApi.confirmWechatPayPayment(
                        client_secret,
                        {
                            payment_method_options: {
                                wechat_pay: {
                                    client: "web",
                                },
                            },
                        },
                        {
                            handleActions: false,
                        },
                    );
                    break;
                default:
                    throw new Error(`Unhandled wallet method: ${key}`);
            }

            invariant(intentConfirmResult.paymentIntent, "Expected paymentIntent in confirmation response");

            nextAction = intentConfirmResult.paymentIntent.next_action;

            invariant(nextAction, "Expected nextAction to be present in response");

            const url: string | null | undefined =
                nextAction.type === "alipay_handle_redirect"
                    ? (nextAction as AlipayHandleRedirect).alipay_handle_redirect.url
                    : nextAction.redirect_to_url?.url;

            if (url) {
                progress("Redirecting to payment gateway");
                invariant(popup, "Popup must be provided");
                popup.handleRedirect(url);
            }

            if (nextAction.type === "wechat_pay_display_qr_code") {
                invariant(nextAction.wechat_pay_display_qr_code?.image_data_url, "QR code image must be provided");
                setQrCode({ qrcode_url: nextAction.wechat_pay_display_qr_code?.image_data_url });
                if (nextAction.wechat_pay_display_qr_code?.data) {
                    console.log(
                        "Confirm payment here for testing: %c%s",
                        "text-decoration: underline;",
                        nextAction.wechat_pay_display_qr_code?.data,
                    );
                }
            }

            const reference = data.pay_intent_id;

            progress("Waiting for payment confirmation");
            return await pollForSuccess({
                signal,
                popup,
                check: async () => {
                    const creditList = await creditListRequest(apiClient, account.id, signal);
                    return creditList.some((c) => c.reference === `stripe_wallet::${reference}`);
                },
            });

            // /app/payment_return/?payment_intent=pi_3PzYfxI7ApJ8GXEs0BsnRuBQ&payment_intent_client_secret=pi_3PzYfxI7ApJ8GXEs0BsnRuBQ_secret_hyUFUpJtP7JvAMWL5AvGcfb8H&redirect_status=succeeded&stripe_wallet_return=1
        },
    }));
    //#endregion

    if (qrCode) {
        // TODO: Loading indicator for image (since it takes a while to load)
        return (
            <div className="bg-white">
                <img src={qrCode.qrcode_url} alt="QR code" />
                <p>Scan the QR code to pay</p>
            </div>
        );
    }

    return null;
}

export default StripeLinkTopUpForm;
