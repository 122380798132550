import { useQueryClient } from "@tanstack/react-query";
import { notNull } from "@warrenio/utils/notNull";
import { useAtomValue } from "jotai/react";
import { useRef, useState } from "react";
import invariant from "tiny-invariant";
import { showWarn } from "../../../dev/errorStream.ts";
import { unhandledNestedError } from "../../../utils/error.ts";
import type { PaymentMethod } from "../PaymentMethod.tsx";
import { processorsAtom } from "../paymentProcessorsLogic.tsx";
import { getPaymentReturnUrl } from "../topup/urls.ts";
import type { AddMethodActions, AddPaymentMethodParams } from "./AddMethodParams.ts";
import type { ChooseMethodContentProps, ChooseMethodProps } from "./ChooseMethod.tsx";
import { invalidateBillingCards } from "./addMethodUtils.ts";

export interface UseAddMethodProps {
    defaultValue?: ChooseMethodProps["value"];
}

export function useAddMethod(props?: UseAddMethodProps) {
    const methods = useAtomValue(processorsAtom);
    return useAddMethodWithProcessors(
        [...methods.values()].filter((m) => m.canAdd),
        props,
    );
}

export function useAddMethodWithProcessors(methods: PaymentMethod[], props?: UseAddMethodProps) {
    const queryClient = useQueryClient();

    let defaultValue = props?.defaultValue ?? null;
    if (defaultValue != null && !methods.some((m) => m.id === defaultValue)) {
        showWarn(
            "Invalid default value for `useAddMethod`: %o (valid: %o)",
            defaultValue,
            methods.map((m) => m.id),
        );
        defaultValue = null;
    }

    const [value, setValue] = useState<ChooseMethodProps["value"]>(defaultValue);

    // Forward actions to the form via a ref / `useImperativeHandle`
    const actionsRef = useRef<AddMethodActions>(null);
    function getActions() {
        return notNull(actionsRef.current, "form ref");
    }

    return {
        async validate() {
            if (value == null) {
                console.warn("No payment method selected");
                return false;
            }

            return await getActions().validate();
        },

        async addPaymentMethod(params: AddPaymentMethodParams) {
            const isValid = await getActions().validate();
            invariant(isValid, "Should only be called when form is valid");

            try {
                return await getActions().addPaymentMethod({
                    // TODO: Implement a separate return page for adding methods
                    returnUrl: getPaymentReturnUrl(),
                    ...params,
                });
            } finally {
                try {
                    await invalidateBillingCards(queryClient, params.account.id);
                } catch (e) {
                    unhandledNestedError(e);
                }
            }
        },

        props: {
            formProps: { actionsRef },
            value,
            onChange: setValue,
            methods,
            // TODO: Set `isDisabled` when action is in progress
        } satisfies ChooseMethodContentProps,
        hasSelectedMethod: value != null,
    };
}
